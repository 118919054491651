import store from "../store/store";
import {
  adjustment1,
  codeStruct,
  exam1,
  ICareplan,
  IInsurance,
} from "../types";
import { Data } from "./interface";
import { message } from "antd";
// import colors from 'colors'


export interface SingleCodeBreakdown {
  code: number | string;
  quantity: number;
  defaultAmount: number;
  careplanAmount: number;
  defaultCost: number;
  careplanCost: number;
}

export interface CodeBreakdown {
  adjustments: SingleCodeBreakdown[];
  exams: SingleCodeBreakdown[];
  addOns: SingleCodeBreakdown[];
  therapies: SingleCodeBreakdown[];
  xrays: SingleCodeBreakdown[];
  [key: string]: SingleCodeBreakdown[];
}

export const calculateCost = (plan: ICareplan, insurance: IInsurance) => {
  const {
    company,
    allowed_percentage,
    x_ray_percent_coverage,
    x_rays_subject_to_deductable,
  } = insurance;
  const deductLeft =
    insurance.individual_deductable - insurance.individual_deductable_Met;
  const coveredVisits = insurance.visits_allowed - insurance.visits_used;
  const uncoveredVisits = plan.adjustments.qty - coveredVisits;

  const coveredVisitsCost =
    company in (plan.adjustments.feeSchedule.companyCost || {})
      ? coveredVisits * plan.adjustments.feeSchedule.companyCost[company]
      : coveredVisits * plan.adjustments.feeSchedule.cost;

  const coveredVisitsShareCost =
    coveredVisitsCost > deductLeft
      ? ((coveredVisitsCost - deductLeft) * (100 - allowed_percentage)) / 100
      : 0;
  const uncoveredVisitsCost =
    uncoveredVisits * plan.adjustments.feeSchedule.cost;

  const examCost = Object.keys(plan.exams)
    ?.map((ex) => plan.exams[ex].item.cost * plan.exams[ex].qty)
    .reduce((sum, cur) => sum + cur, 0);

  const xrayCost = Object.keys(plan.xrays)
    ?.map((x) => plan.xrays[x].item.cost * plan.xrays[x].qty)
    .reduce((sum, cur) => sum + cur, 0);
  const xrayCostCoverage =
    x_ray_percent_coverage > 0
      ? (xrayCost * (x_ray_percent_coverage || 0)) / 100
      : 0;
  const xrayCostUser = xrayCost - xrayCostCoverage;
  const addonsCost = Object.keys(plan.addons)
    ?.map((ex) => plan.addons[ex].item.cost * plan.addons[ex].qty)
    .reduce((sum, cur) => sum + cur, 0);
  const therapyCost = Object.keys(plan.therapies || {})
    .map((ex) => plan.therapies[ex].item.cost * plan.therapies[ex].qty)
    .reduce((sum, cur) => sum + cur, 0);
  const insuranceCost =
    coveredVisitsCost - coveredVisitsShareCost + xrayCostCoverage;

  const userCost =
    coveredVisitsShareCost +
    uncoveredVisitsCost +
    examCost +
    xrayCostUser +
    therapyCost +
    addonsCost;
  return { insuranceCost, userCost };
};

export const carePlanCalculation = (
  codeList: codeStruct[],
  clientPlan: any,
  defaultFS: any
) => {
  if (clientPlan.caseType !== "Insurance") {
    return latestCalculations(codeList, clientPlan, defaultFS);
  } else {
    // return insuranceCalculationFix(codeList, clientPlan, defaultFS);
    return insuranceCalculation(codeList, clientPlan, defaultFS);
  }
};

// calculation for without insurance

export const latestCalculations = (
  codeList: codeStruct[],
  clientPlan: any,
  defaultFS: any
) => {
  // console.log("clie",clientPlan);
  const placeHolderData: Data = {} as Data;
  const codesBreakdown: any = {
    adjustments: [],
    exams: [],
    addOns: [],
    xrays: [],
    therapies: [],
  };
  let insuranceVisits = clientPlan.insuranceVisits;
  // console.log("plan", clientPlan);

  if (!clientPlan.carePlan)
    return {
      costSummary: {
        userCost: 0,
        totalCost: 0,
        insuranceCoverage: 0,
        monthlyCost: 0,
        defaultFeeScheduleCost: 0,
      },
      placeHolderData: {},
      codesBreakdown,
    };
  if (clientPlan.caseType !== "Insurance") insuranceVisits = 0;
  Object.values(clientPlan.carePlan.Exams).forEach((item: any) => {
    codesBreakdown.exams.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.AddOns).forEach((item: any) => {
    codesBreakdown.addOns.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.Adjustments).forEach((item: any) => {
    codesBreakdown.adjustments.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.XRays).forEach((item: any) => {
    codesBreakdown.xrays.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.Therapies).forEach((item: any) => {
    codesBreakdown.therapies.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  const adjustments: any = Object.values(clientPlan.carePlan.Adjustments);
  const exams = Object.values(clientPlan.carePlan.Exams);
  const xrays = Object.values(clientPlan.carePlan.XRays);
  const therapies = Object.values(clientPlan.carePlan.Therapies);
  const addOns = Object.values(clientPlan.carePlan.AddOns);

  const getCodeCost2 = (items: any, name: string) => {
    let covered = 0;
    let uncovered = 0;
    let defaultCost = 0;
    let discounted = 0;

    if (!items.length) return { covered, uncovered, defaultCost, discounted };
    // console.log("items ", items);

    items.forEach((item: any) => {
      const code = codeList?.find((code) => item.code == code.code);

      const amount =
        code?.amount?.[clientPlan.feeSchedule] ||
        code?.amount?.[defaultFS._id] ||
        0;

      // discountedAmount
      const discounteded =
        code?.discountedAmount?.[clientPlan.feeSchedule] || amount;

      const defaultAmount = code?.amount?.[defaultFS?.id] || 0;
      const dcost = defaultAmount * item.visits.length;
      defaultCost += dcost;

      // console.log("length vis", item.visits.length);

      if (item.visits.length >= 1) {
        item.visits.forEach((vis: number) => {
          if (vis <= insuranceVisits) {
            // console.log("fist vis", vis);
            covered += amount;
            // discounted += (amount -discountedAmount )
          } else {
            // console.log("2nd vis", vis);
            uncovered += amount;
            discounted += discounteded;
          }
        });
      }
    });

    return { covered, uncovered, defaultCost, discounted };
  };
  const adjustmentCost = getCodeCost2(adjustments, "adjustments");
  const examsCost = getCodeCost2(exams, "exams");
  const xraysCost = getCodeCost2(xrays, "xrays");
  const therapiesCost = getCodeCost2(therapies, "therapies");
  const addonsCost = getCodeCost2(addOns, "addOns");

  const insuranceCoverage = Number(
    (
      adjustmentCost.covered +
      examsCost.covered +
      therapiesCost.covered +
      xraysCost.covered +
      addonsCost.covered
    ).toFixed(2)
  );
  const userCost = Number(
    (
      adjustmentCost.uncovered +
      examsCost.uncovered +
      therapiesCost.uncovered +
      xraysCost.uncovered +
      addonsCost.uncovered
    ).toFixed(2)
  );
  const discount = Number(
    (
      Number(adjustmentCost.discounted) +
      Number(examsCost.discounted) +
      Number(therapiesCost.discounted) +
      Number(xraysCost.discounted) +
      Number(addonsCost.discounted)
    ).toFixed(2)
  );
  const discountedAmount = Number(discount);
  const totalCost = insuranceCoverage + userCost;
  const monthlyCost = Number(
    (userCost / clientPlan.carePlan.months).toFixed(2)
  );
  const defaultFeeScheduleCost =
    adjustmentCost.defaultCost +
    examsCost.defaultCost +
    therapiesCost.defaultCost +
    xraysCost.defaultCost +
    addonsCost.defaultCost;

  placeHolderData["{totalVisit}"] = clientPlan.carePlan.visits;
  placeHolderData["{totalCost}"] = totalCost;
  placeHolderData["{totalMonths}"] = clientPlan.carePlan.months;
  placeHolderData["{fivePerWeek}"] = clientPlan.carePlan.frequency.fiveperweek;
  placeHolderData["{fourPerWeek}"] = clientPlan.carePlan.frequency.fourperweek;
  placeHolderData["{threePerWeek}"] =
    clientPlan.carePlan.frequency.threeperweek;
  placeHolderData["{twoPerWeek}"] = clientPlan.carePlan.frequency.twoperweek;
  placeHolderData["{onePerWeek}"] = clientPlan.carePlan.frequency.oneperweek;
  placeHolderData["{everyPerWeek}"] =
    clientPlan.carePlan.frequency.everyperweek;
  placeHolderData["{stageOfCare}"] = clientPlan.stageOfCare;
  placeHolderData["{totalDefaultFeeSchedulePrice}"] = defaultFeeScheduleCost;
  placeHolderData["{totalCareplanPrice}"] = totalCost;
  placeHolderData["{outOfPocket}"] = userCost;
  placeHolderData["{discountedPrice}"] = discountedAmount || userCost;
  placeHolderData["{insuranceCoverage}"] = insuranceCoverage;
  placeHolderData["{monthlyPrice}"] = monthlyCost;
  // placeHolderData["{patientName}"] = 'john doe';
  placeHolderData["{phaseOfDegeneration}"] = clientPlan.phaseOfDegenration;
  placeHolderData["{feeSchedule}"] = clientPlan.feeScheduleName;
  placeHolderData["{careplanTemplateName}"] = clientPlan.planName;
  // need to add placeholder data for exams, xray, addons and therapies like adjustment. But they have muiltiple items.
  const costSummary = {
    totalCost,
    insuranceCoverage,
    userCost,
    monthlyCost,
    defaultFeeScheduleCost,
    discountedAmount,
  };
  // console.log(
  //   "L",
  //   totalCost,
  //   insuranceCoverage,
  //   userCost,
  //   monthlyCost,
  //   defaultFeeScheduleCost,
  //   discountedAmount
  // );
  return { costSummary, placeHolderData, codesBreakdown };
};

// Update calculation steps
// Return default full cost
// user cost -> new calculation
// insurance coverage -> new calculation
// insurance savings -> new

export const insuranceCalculation = (
  codeList: codeStruct[],
  clientPlan: any,
  defaultFS: any
) => {
  const storeData = store.getState();
  // console.log("store data ", { storeData });
  const insurance = storeData.patient.insurance;

  // console.log("ins", insurance);

  const placeHolderData: Data = {} as Data;
  // const codesBreakdown: CodeBreakdown = {
  //   adjustments: [],
  //   exams: [],
  //   addOns: [],
  //   xrays: [],
  //   therapies: [],
  // };
  const codesBreakdown: any = {
    adjustments: [],
    exams: [],
    addOns: [],
    xrays: [],
    therapies: [],
  };

  // remaining visits
  // const insuranceVisits = insurance.visits_allowed - insurance.visits_used;

  // deductableLeft

  const deductableLeft = insurance.remaining_deductable;

  // const co_insurance_persantage = insurance.allowed_percentage;
  if (!clientPlan.carePlan)
    return {
      costSummary: {
        userCost: 0,
        totalCost: 0,
        insuranceCoverage: 0,
        monthlyCost: 0,
        defaultFeeScheduleCost: 0,

      },
      placeHolderData: {},
      codesBreakdown,
    };

  const calculations = {
    deductableMet: 0,
    insuranceCoverage: 0,
    userCost: 0,
    totalcost: 0,
  };
  const examDays=[]
  const xraysDays=[]

  console.log("cleint plan", clientPlan);
  // clientPlan.insuranceVisits
  // console.log("careplan", clientPlan.carePlan);
  console.log("ins", insurance);
  console.log("visits", clientPlan.carePlan.visits);
  for (let i = 1; i <= clientPlan.carePlan.visits; i++) {
    let currentVisitCost = 0;
    let currentVisitSaved = 0;

    const adjustmentCost = getCodeCost(
      clientPlan.carePlan.Adjustments,
      i,
      clientPlan.feeSchedule,
      defaultFS._id,
      codeList,
      clientPlan.carePlan.visits,
      "adjustment",
      insurance,
      calculations.deductableMet + currentVisitCost,
      clientPlan.insuranceVisits
    );
    console.log(
      "adjustmentCost chiro_benefit_989XX",
      i,
      adjustmentCost.saved,
      adjustmentCost.amount
    );

    currentVisitCost += adjustmentCost.amount;
    currentVisitSaved += adjustmentCost.saved;

    const examCost = getCodeCost(
      clientPlan.carePlan.Exams,
      i,
      clientPlan.feeSchedule,
      defaultFS._id,
      codeList,
      clientPlan.carePlan.visits,
      "exam",
      insurance,
      calculations.deductableMet + currentVisitCost,
      clientPlan.insuranceVisits
    );
    console.log("examCost office_visit_992XX", i, examCost.saved, examCost.amount);
    currentVisitCost += examCost.amount;
    currentVisitSaved += examCost.saved;


    const xrayCost = getCodeCost(
      clientPlan.carePlan.XRays,
      i,
      clientPlan.feeSchedule,
      defaultFS._id,
      codeList,
      clientPlan.carePlan.visits,
      "xrays",
      insurance,
      calculations.deductableMet + currentVisitCost,
      clientPlan.insuranceVisits
    );
    console.log("xrayCost diagnostic_72XXX", i, xrayCost.saved, xrayCost.amount);

    currentVisitCost += xrayCost.amount;
    currentVisitSaved += xrayCost.saved;

    const therapiesCost = getCodeCost(
      clientPlan.carePlan.Therapies,
      i,
      clientPlan.feeSchedule,
      defaultFS._id,
      codeList,
      clientPlan.carePlan.visits,
      "therapies",
      insurance,
      calculations.deductableMet + currentVisitCost,
      clientPlan.insuranceVisits
    );
    console.log("therapiesCost physical_therapy_97XXX", i, therapiesCost.saved, therapiesCost.amount);

    currentVisitCost += therapiesCost.amount;
    currentVisitSaved += therapiesCost.saved;

    const addonsCost = getCodeCost(
      clientPlan.carePlan.AddOns,
      i,
      clientPlan.feeSchedule,
      defaultFS._id,
      codeList,
      clientPlan.carePlan.visits,
      "addons",
      insurance,
      calculations.deductableMet + currentVisitCost,
      clientPlan.insuranceVisits
    );
    console.log("addonsCost", i, addonsCost.saved, addonsCost.amount);
    currentVisitCost += addonsCost.amount;
    currentVisitSaved += addonsCost.saved;


    calculations.deductableMet += currentVisitCost;


     //   user cost with deductbible left

    // calculations.userCost =
    //   calculations.deductableMet - deductableLeft > 0
    //     ? calculations.deductableMet - deductableLeft
    //     : 0;


    // updated  user cost without deductbible left

    calculations.userCost = calculations.deductableMet
    calculations.totalcost += currentVisitSaved + currentVisitCost;

    calculations.insuranceCoverage += currentVisitSaved;
  }
  console.log("total", calculations);
  const defaultFullCost = getDefaultFullCost(
    codeList,
    clientPlan.carePlan,
    defaultFS._id
  );

  placeHolderData["{totalVisit}"] = clientPlan.carePlan.visits;
  placeHolderData["{totalCost}"] = defaultFullCost;
  placeHolderData["{totalMonths}"] = clientPlan.carePlan.months;
  placeHolderData["{fivePerWeek}"] = clientPlan.carePlan.frequency.fiveperweek;
  placeHolderData["{fourPerWeek}"] = clientPlan.carePlan.frequency.fourperweek;
  placeHolderData["{threePerWeek}"] =
    clientPlan.carePlan.frequency.threeperweek;
  placeHolderData["{twoPerWeek}"] = clientPlan.carePlan.frequency.twoperweek;
  placeHolderData["{onePerWeek}"] = clientPlan.carePlan.frequency.oneperweek;
  placeHolderData["{everyPerWeek}"] =
    clientPlan.carePlan.frequency.everyperweek;
  placeHolderData["{stageOfCare}"] = clientPlan.stageOfCare;
  placeHolderData["{totalDefaultFeeSchedulePrice}"] = defaultFullCost;
  placeHolderData["{totalCareplanPrice}"] = defaultFullCost;
  placeHolderData["{outOfPocket}"] = reducedNumberToFixed(
    calculations.userCost
  );
  placeHolderData["{discountedPrice}"] = calculations.userCost;
  placeHolderData["{insuranceCoverage}"] = reducedNumberToFixed(
    calculations.insuranceCoverage
  );
  placeHolderData["{monthlyPrice}"] = reducedNumberToFixed(
    calculations.userCost / clientPlan.carePlan.months
  );
  // placeHolderData["{patientName}"] = 'john doe';
  placeHolderData["{phaseOfDegeneration}"] = clientPlan.phaseOfDegenration;
  placeHolderData["{feeSchedule}"] = clientPlan.feeScheduleName;
  placeHolderData["{careplanTemplateName}"] = clientPlan.planName;
  Object.values(clientPlan.carePlan.Exams).forEach((item: any) => {
    codesBreakdown.exams.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.AddOns).forEach((item: any) => {
    codesBreakdown.addOns.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.Adjustments).forEach((item: any) => {
    codesBreakdown.adjustments.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.XRays).forEach((item: any) => {
    codesBreakdown.xrays.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  Object.values(clientPlan.carePlan.Therapies).forEach((item: any) => {
    codesBreakdown.therapies.push({
      code: item.code,
      quantity: item.visits.length,
    });
  });
  return {
    costSummary: {
      userCost: reducedNumberToFixed(calculations.userCost),
      totalCost: reducedNumberToFixed(calculations.totalcost),
      insuranceCoverage: reducedNumberToFixed(calculations.insuranceCoverage),
      // insuranceSavings: reducedNumberToFixed(calculations.insuranceCoverage),
      monthlyCost: reducedNumberToFixed(
        calculations.userCost / clientPlan.carePlan.months
      ),
      defaultFeeScheduleCost: defaultFullCost,
    },
    placeHolderData,
    codesBreakdown,
  };
};

const getDefaultCodeAmount = (
  codeList: codeStruct[],
  codeId: number,
  defaultFS: string
) => {
  const code = codeList?.find((item) => item.code == codeId);
  const amount = code?.amount[defaultFS] || 0;
  return amount;
};

const getDefaultFullCost = (
  codeList: codeStruct[],
  carePlan: any,
  defaultFS: string
) => {
  const adjustmentCost = Object.values(carePlan.Adjustments)
    .map((codeItem: any) => {
      return (
        getDefaultCodeAmount(codeList, codeItem.code, defaultFS) *
        codeItem.visits.length
      );
    })
    .reduce((total, current) => total + current, 0);
  const examsCost = Object.values(carePlan.Exams)
    .map((codeItem: any) => {
      return (
        getDefaultCodeAmount(codeList, codeItem.code, defaultFS) *
        codeItem.visits.length
      );
    })
    .reduce((total, current) => total + current, 0);
  const therapyCost = Object.values(carePlan.Therapies)
    .map((codeItem: any) => {
      return (
        getDefaultCodeAmount(codeList, codeItem.code, defaultFS) *
        codeItem.visits.length
      );
    })
    .reduce((total, current) => total + current, 0);
  const addonsCost = Object.values(carePlan.AddOns)
    .map((codeItem: any) => {
      return (
        getDefaultCodeAmount(codeList, codeItem.code, defaultFS) *
        codeItem.visits.length
      );
    })
    .reduce((total, current) => total + current, 0);
  const xraysCost = Object.values(carePlan.XRays)
    .map((codeItem: any) => {
      return (
        getDefaultCodeAmount(codeList, codeItem.code, defaultFS) *
        codeItem.visits.length
      );
    })
    .reduce((total, current) => total + current, 0);
  return xraysCost + addonsCost + therapyCost + examsCost + adjustmentCost;
};

const getCodeCost = (
  planItem: any,
  i: number,
  feeSchedule: string,
  defaultFS: string,
  codeList: codeStruct[],
  visits: any,
  itemName: string,
  insurance: any,
  deductableMet: number,
  usedVisits: number
) => {
  const deductableLeft = Number(insurance.remaining_deductable);

  // there have 3 kind of visits

  // 992 = exam
  // 989= adjustment
  // 97=  therapy
  // 72= x-rays
  // const usingVisits = usedVisits;
  // const chiroBenefit989 = insurance.chiro_benefit_remaining;
  // const physicalTherapy97 = insurance.physical_therapy_remaining;
  // const diagnostic72 = insurance.diagnostic_remaining || 0;

  let currentuvx = 0
  let currentuva = 0

  const cost2 = Object.values(planItem).map((codeItem: any) => {
    if (itemName == "exam" && codeItem.visits.length > 0) {
      // const examDistance = visits / codeItem.visits.length;
      const getDate = codeItem.visits.find((d: any) => d == i);
      if (getDate) {
        const code = codeList?.find((item) => item.code == codeItem.code);
        const mainAmount =
          code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

        if (insurance.office_visit_992XX === "co_pay" && i <= usedVisits) {
          if (!insurance.office_visit_co_pay) {
            alert("office visits co-pay amount not found");
            return;
          }
          const amount = insurance.office_visit_co_pay;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.office_visit_992XX === "co_insurance" &&
          i <= usedVisits &&
          deductableMet >= deductableLeft
        ) {
          if (!insurance.office_visit_co_insurance) {
            alert("office visits co-insurance amount not found");
            return;
          }
          const totalAmount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

          //   const saved =
          //   (totalAmount * insurance.diagnostic_co_insurance) / 100;

          // const amount = Number(mainAmount) - Number(saved);

          const amount =
            (totalAmount * insurance.office_visit_co_insurance) / 100;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.office_visit_992XX === "covered" &&
          i <= usedVisits &&
          deductableMet >= deductableLeft
        ) {
          const amount = 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else {
          const amount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        }
      } else {
        return { amount: 0, saved: 0 };
      }
    }
    else if (itemName == "xrays" && codeItem.visits.length > 0) {
      const getDate = codeItem.visits.find((d: any) => d == i);
      if (getDate) {
        const code = codeList?.find((item) => item.code == codeItem.code);
        const mainAmount =
          code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

        if (insurance.diagnostic_72XXX === "co_pay" && i <= usedVisits) {
          if (!insurance.diagnostic_co_pay) {
            alert("diagnostic co-pay amount not found");
            return;
          }

  // updated
  let amount
  if(i != currentuvx ) {
    amount =insurance.diagnostic_co_pay
    currentuvx=i
  }
  else {
    amount = 0
  }
  // updated

          // const amount = insurance.diagnostic_co_pay;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.diagnostic_72XXX === "co_insurance" &&
          i <= usedVisits &&
          deductableMet >= deductableLeft
        ) {
          if (!insurance.diagnostic_co_insurance) {
            alert("diagnostic co-insurance amount not found");
            return;
          }
          const totalAmount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

          //   const saved =
          //   (totalAmount * insurance.diagnostic_co_insurance) / 100;

          // const amount = Number(mainAmount) - Number(saved);

          const amount =
            (totalAmount * insurance.diagnostic_co_insurance) / 100;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.diagnostic_72XXX === "covered" &&
          i <= usedVisits &&
          deductableMet >= deductableLeft
        ) {
          const amount = 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else {
          const amount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        }
      } else {
        return { amount: 0, saved: 0 };
      }
    }
    //  else if (itemName == "xrays" && codeItem.visits.length > 0) {
    //   if (i == visits) {
    //     const code = codeList?.find((item) => item.code == codeItem.code);
    //     const mainAmount =
    //       code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

    //     if (insurance.diagnostic_72XXX === "co_pay") {
    //       if (!insurance.diagnostic_72XXX) {
    //         alert("diagnostic co-pay amount not found");
    //         return;
    //       }
    //       const amount = insurance.diagnostic_co_pay;

    //       const saved = Number(mainAmount) - Number(amount);
    //       console.log("xrays amout co pay", amount);
    //       console.log("xrays save co pay", saved);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else if (
    //       insurance.diagnostic_72XXX === "co_insurance" &&
    //       deductableMet >= deductableLeft
    //     ) {
    //       if (!insurance.diagnostic_co_insurance) {
    //         alert("diagnostic co-insurance amount not found");
    //         return;
    //       }
    //       const totalAmount =
    //         code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

    //       const amount =
    //         (totalAmount * insurance.diagnostic_co_insurance) / 100;

    //       const saved = Number(mainAmount) - Number(amount);

    //       console.log("xrays amout co ins", amount);
    //       console.log("xrays save co ins", saved);

    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else if (
    //       insurance.diagnostic_72XXX === "covered" &&
    //       // i == usedVisits &&
    //       deductableMet >= deductableLeft
    //     ) {
    //       const amount = 0;
    //       const saved = Number(mainAmount) - Number(amount);
    //       console.log("xrays amoutcoveredy", amount);
    //       console.log("xrays save covered", saved);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else {
    //       const amount =
    //         code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
    //       const saved = Number(mainAmount) - Number(amount);

    //       console.log("xrays amout un covered", amount);
    //       console.log("xrays save un covered", saved);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     }
    //   } else {
    //     return { amount: 0, saved: 0 };
    //   }
    // }
     else if (itemName == "addons" && codeItem.visits.length > 0) {
      if (i == visits) {
        const code = codeList?.find((item) => item.code == codeItem.code);
        const mainAmount =
          code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

        const amount =
          code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
        const saved = Number(mainAmount) - Number(amount);
        if (!isNaN(saved)) {
          return { amount, saved };
        } else {
          return { amount: 0, saved: 0 };
        }
      } else {
        return { amount: 0, saved: 0 };
      }
    }
    else if (itemName == "therapies" && codeItem.visits.length > 0) {
      // const examDistance = visits / codeItem.visits.length;
      const getDate = codeItem.visits.find((d: any) => d == i);
      if (getDate) {
        const code = codeList?.find((item) => item.code == codeItem.code);
        const mainAmount =
          code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

        if (insurance.physical_therapy_97XXX === "co_pay"
        && i <= usedVisits
        && i<=insurance.physical_therapy_remaining
        ) {
          if (!insurance.physical_therapy_co_pay) {
            alert("physical_therapy co-pay amount not found");
            return;
          }
          const amount = insurance.physical_therapy_co_pay;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.physical_therapy_97XXX === "co_insurance" &&
          i <= usedVisits
          && i<=insurance.physical_therapy_remaining &&
          deductableMet >= deductableLeft
        ) {
          if (!insurance.physical_therapy_co_insurance) {
            alert("physical_therapy co-insurance amount not found");
            return;
          }
          const totalAmount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

          //   const saved =
          //   (totalAmount * insurance.diagnostic_co_insurance) / 100;

          // const amount = Number(mainAmount) - Number(saved);

          const amount =
            (totalAmount * insurance.physical_therapy_co_insurance) / 100;

          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.physical_therapy_97XXX === "covered" &&
          i <= usedVisits
          && i<=insurance.physical_therapy_remaining &&
          deductableMet >= deductableLeft
        ) {
          const amount = 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else {
          const amount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        }
      } else {
        return { amount: 0, saved: 0 };
      }
    }
    //  else if (itemName == "therapies" && codeItem.visits.length > 0) {
    //   if (i == visits) {
    //     const code = codeList?.find((item) => item.code == codeItem.code);
    //     const mainAmount =
    //       code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

    //     if (
    //       insurance.physical_therapy_97XXX === "co_pay" &&
    //       visits <= insurance.physical_therapy_remaining
    //     ) {
    //       if (!insurance.physical_therapy_97XXX) {
    //         alert("therapy co-pay amount not found");
    //         return;
    //       }
    //       const amount = insurance.physical_therapy_co_pay;
    //       const saved = Number(mainAmount) - Number(amount);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else if (
    //       insurance.physical_therapy_97XXX === "co_insurance" &&
    //       visits <= insurance.physical_therapy_remaining &&
    //       deductableMet >= deductableLeft
    //     ) {
    //       if (!insurance.physical_therapy_co_insurance) {
    //         alert("therapy co-insurance amount not found");
    //         return;
    //       }
    //       const totalAmount =
    //         code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
    //       const amount =
    //         (totalAmount * insurance.physical_therapy_co_insurance) / 100;

    //       const saved = Number(mainAmount) - Number(amount);

    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else if (
    //       insurance.physical_therapy_97XXX === "covered" &&
    //       visits <= insurance.physical_therapy_remaining &&
    //       deductableMet >= deductableLeft
    //     ) {
    //       const amount = 0;
    //       const saved = Number(mainAmount) - Number(amount);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     } else {
    //       const amount =
    //         code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
    //       const saved = Number(mainAmount) - Number(amount);
    //       if (!isNaN(saved)) {
    //         return { amount, saved };
    //       }
    //     }
    //   } else {
    //     return { amount: 0, saved: 0 };
    //   }
    // }
     else if (itemName == "adjustment" && codeItem.visits.length > 0) {
      const code = codeList?.find((item) => item.code == codeItem.code);
      const mainAmount =
        code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;

      const getDate = codeItem?.visits?.find((d: any) => d == i);

      if (getDate) {
        if (insurance.chiro_benefit_989XX === "co_pay" && i <= usedVisits && i<=insurance.chiro_benefit_remaining) {
          if (!insurance.chiro_benefit_co_pay) {
            alert("office visits co-pay amount not found");
            return;
          }


  // updated
  let amount
  if(i != currentuva ) {
    amount =insurance.chiro_benefit_co_pay
    currentuva=i
  }
  else {
    amount = 0 
  }
  // updated

          // const amount = insurance.chiro_benefit_co_pay;


          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.chiro_benefit_989XX === "co_insurance" &&
          i <= usedVisits && i<=insurance.chiro_benefit_remaining &&
          deductableMet >= deductableLeft
        ) {
          console.log("met", deductableMet, "left", deductableLeft );
          if (!insurance.chiro_benefit_co_insurance) {
            alert("office visits co-insurance amount not found");
            return;
          }
          const totalAmount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
          const amount =
            (totalAmount * insurance.chiro_benefit_co_insurance) / 100;

          const saved = Number(mainAmount) - Number(amount);

          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else if (
          insurance.chiro_benefit_989XX === "covered" &&
          i <= usedVisits && i<=insurance.chiro_benefit_remaining &&
          deductableMet >= deductableLeft
        ) {
          const amount = 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        } else {
          const amount =
            code?.amount[feeSchedule] || code?.amount[defaultFS] || 0;
          const saved = Number(mainAmount) - Number(amount);
          if (!isNaN(saved)) {
            return { amount, saved };
          }
        }
      } else {
        return { amount: 0, saved: 0 };
      }
    }

    return { amount: 0, saved: 0 };
  });

  // console.log("ins", insurance);
  // .reduce((total, current) => total + current, 0);
  type Consttype = {
    amount: number;
    saved: number;
  };
  const result: Consttype = { amount: 0, saved: 0 };
  cost2.forEach((obj: any) => {
    result.amount += Number(obj.amount);
    result.saved += Number(obj.saved);
  });

  const fixedAmount = result.amount.toFixed(2);
  const fixedSaved = result.saved.toFixed(2);
  const newResult: Consttype = {
    amount: Number(fixedAmount),
    saved: Number(fixedSaved),
  };

  // if (result.amount >= 0 || result.amount >= 0) {

  return newResult;
  // }
  //  else {
  //   return {
  //     amount: 0,
  //     saved: 0,
  //   };
  // }
};

export const reducedNumberToFixed = (num: number, fix = 2) => {
  return Number(num.toFixed(fix));
};

// strategy
/*
    deductableLeft = 1740
    userPay = 0
    insuranceCoverage = 0;
    insuranceSavings = 0;
    visit left = 19
    deductableMet = 0;

  * add current visit cost to deductable met until it remain less then deductable left
  * if it is going above deductable left, add to user cost
  * if visit number is less then visit left, calculate with user co insurance %
  * add cost to user cost
  * add savings to insurance savings
  * return calculation

*/
